import { ACTION_NEW_TRANSFER_FAILURE } from '../../containers/transfers/newTransfer/newTransfer.reducer';
import type { ReduxState } from '../../reducers';
import type { Action } from '../../utilities/types';
import pageAnalytics from '../pageAnalytics';
import { defaults } from '../../components/error/error';
import { FlashMessageText } from '../../components/flashMessage/flashMessage.constants';
import { ANALYTICS_NEW_TRANSFER_LOADED, ANALYTICS_EXTERNAL_TRANSFER_LIMITS } from '../actions';
import pageTrack from '../pageAnalytics.constants';

const initPageData = {
  account_type: 'generic',
  pagekind: 'transfers',
  pagefunction: 'make_transfer',
} as const;

const viewSuccess = (action: Action, state: ReduxState) => {
  let pagesubfunction = 'invalid';

  if (
    state.newTransfer.transfer &&
    state.newTransfer.transfer.fromAccount &&
    state.newTransfer.transfer.toAccount
  ) {
    const isFromTypeExternal = state.newTransfer.transfer.fromAccount.external;
    const isToTypeExternal = state.newTransfer.transfer.toAccount.external;

    if (isFromTypeExternal) {
      // Can't do external-to-external transfers
      pagesubfunction = pageTrack.pagesubfunction.INWARD;
    } else {
      pagesubfunction = isToTypeExternal
        ? pageTrack.pagesubfunction.OUTWARD
        : pageTrack.pagesubfunction.INTERNAL;
    }
  }
  pageAnalytics.newPageView(initPageData);
  const { pageFlashMessage } = state.flashMessage;

  // if no flash message
  if (!pageFlashMessage.messageText) {
    pageAnalytics.addInfo({ pagename: 'start' });
  }
  // else if specific flash error
  else if (pageFlashMessage.messageText === FlashMessageText.TRANSFER_ERROR_TOO_FEW_ACCOUNTS) {
    pageAnalytics.addInfo({ pagename: 'start_not_enough_accounts' });
  }
  // else any other flash message
  else if (pageFlashMessage.messageText) {
    pageAnalytics.addInfo({
      pagename: pageTrack.pagename.submit_server_error,
      pagesubfunction,
      attr: pageFlashMessage.messageText || defaults.message,
    });
  }

  pageAnalytics.record();
};

const viewFailure = () => {
  pageAnalytics.newPageView(initPageData).addInfo({ pagename: 'start_failed_to_load' }).record();
};

const externalTransferLimits = ({ payload }) => {
  pageAnalytics
    .newPageView({
      pagename: pageTrack.pagename.view,
      pagefunction: pageTrack.pagefunction.external_transfer_limits,
      pagekind: payload,
    })
    .record();
};

export const newTransferPageEvents = {
  [ANALYTICS_NEW_TRANSFER_LOADED]: viewSuccess,
  [ACTION_NEW_TRANSFER_FAILURE]: viewFailure,
  [ANALYTICS_EXTERNAL_TRANSFER_LIMITS]: externalTransferLimits,
} as const;

export default newTransferPageEvents;
