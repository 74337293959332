// Focus an element forcefully in voiceover
export const setVoiceOverFocus = (element: HTMLElement) => {
  const focusInterval = 10; // ms, time between function calls
  const focusTotalRepetitions = 10; // number of repetitions

  element.setAttribute('tabindex', '0');
  element.blur();

  let focusRepetitions = 0;
  const interval = window.setInterval(function () {
    element.focus();
    focusRepetitions += 1;
    if (focusRepetitions >= focusTotalRepetitions) {
      window.clearInterval(interval);
    }
  }, focusInterval);
};

export default setVoiceOverFocus;
